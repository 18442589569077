import Script from "next/script"
import DynamicComponent from ".."

export default function Location({ blok }) {
  const address = blok.geocoded_address.address.split(", ")

  return (
    <>
      {blok?.body ? (
        <main className="page-content">
          {blok.body.map((blok) => (
            <DynamicComponent blok={blok} key={blok._uid} />
          ))}
        </main>
      ) : null}
      <Script type="application/ld+json" id="all-location-schema">
        {`{
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "name": "Dogtown ${blok.name}",
          "image": "",
          "@id": "",
          "url": "https://dogtowncentral.com/${blok.full_slug}",
          "telephone": "${blok.primary_phone}",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "${address[0]}",
            "addressLocality": "${address[1]}",
            "addressRegion": "${address[2].split(" ")[0]}",
            "postalCode": "${address[2].split(" ")[1]}",
            "addressCountry": "US"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": ${blok.geocoded_address.lat},
            "longitude": ${blok.geocoded_address.lng}
          },
          ${blok?.instagram && blok.instagram !== "" ? `"sameAs": "${blok.instagram}",` : ""}
          "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday"
            ],
            "opens": "00:00",
            "closes": "23:59"
          }
        }`}
      </Script>
    </>
  )
}
